export default [
  // {
  //   '0': 'OJP2/A/P0/01',
  //   '1': 'A',
  //   '2': '01',
  //   '3': 'P0',
  //   '4': '80,2',
  //   '5': '-', // dwa
  //   '6': '-', // południe, zachód, wschód
  //   '7': 'Gotowe', // I kwartał 2022
  //   '11': '/assets/files/janowo-2/pdf/OJPII_A_P0_01.pdf',
  //   '12': 'large',
  //   '13': '',
  // },

  {
    "0": "OJP2/A/P0/01",
    "1": "A",
    "2": "01",
    "3": "P0",
    "4": 81.72,
    "5": "2",
    "6": "zachód, północ",
    "7": "Gotowe",
    "12": "large",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_A_P0_01_umowa_rezerw.pdf",
    "14": "service",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/A/P0/02",
    building: "OJP2",
    "2": "02",
    "3": "P0",
    "4": 73.23,
    "1": "A",
    "5": "2",
    "6": "duże przeszklenia, fasada parter wschód",
    "7": "Gotowe",
    "12": "large",
    "13": "",
    "11": "/assets/files/janowo-2/pdf/OJPII_A_P0_02_umowa_rezerw.pdf",
    "14": "service",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/A/P0/03",
    building: "OJP2",
    "2": "03",
    "3": "P0",
    "4": 95.22,
    "1": "A",
    "5": "2",
    "6": "duże przeszklenia, fasada parter wschód",
    "7": "Gotowe",
    "12": "medium",
    "13": "",
    "11": "/assets/files/janowo-2/pdf/OJPII_A_P0_03_umowa_rezerw.pdf",
    "14": "service",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/A/P0/03A",
    building: "OJP2",
    "2": "03",
    "3": "P0",
    "4": 46.03,
    "1": "A",
    "5": "2",
    "6": "duże przeszklenia, fasada parter wschód",
    "7": "Gotowe",
    "12": "medium",
    "13": "",
    "11": "/assets/files/janowo-2/pdf/OJPII_A_P0_03A_umowa_rezerw.pdf",
    "14": "service",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/A/P0/03B",
    building: "OJP2",
    "2": "03",
    "3": "P0",
    "4": 47.78,
    "1": "A",
    "5": "2",
    "6": "duże przeszklenia, fasada parter wschód",
    "7": "Gotowe",
    "12": "medium",
    "13": "",
    "11": "/assets/files/janowo-2/pdf/OJPII_A_P0_03B_umowa_rezerw.pdf",
    "14": "service",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/B/P0/01",
    building: "OJP2",
    "2": "01",
    "3": "P0",
    "4": 43.51,
    "1": "B",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P0_01.pdf",
    "14": "residential",
    extra: "ogródek 18,4m2",
    tour:
      "https://tours.3destate.pl/Process/5ded576b-0090-4a00-a915-eb7eb10579ea.3d",
  },
  {
    "0": "OJP2/B/P0/02",
    building: "OJP2",
    "2": "02",
    "3": "P0",
    "4": 44.25,
    "1": "B",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P0_02.pdf",
    "14": "residential",
    extra: "ogródek 25,8m2",
    tour:
      "https://tours.3destate.pl/Process/7cdd6d9a-2ee4-42e8-bdfa-d7470a74be34.3d",
  },
  {
    "0": "OJP2/B/P0/03",
    building: "OJP2",
    "2": "03",
    "3": "P0",
    "4": 46.05,
    "1": "B",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P0_03.pdf",
    "14": "residential",
    extra: "ogródek 47m2",
    tour:
      "https://tours.3destate.pl/Process/1b91277e-5d09-4eb0-9b00-677b56a255e1.3d",
  },
  {
    "0": "OJP2/B/P0/04",
    building: "OJP2",
    "2": "04",
    "3": "P0",
    "4": 57.09,
    "1": "B",
    "5": "3",
    "6": "zachód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P0_04.pdf",
    "14": "residential",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/B/P0/05",
    building: "OJP2",
    "2": "05",
    "3": "P0",
    "4": 42.0,
    "1": "B",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P0_05.pdf",
    "14": "residential",
    extra: "ogródek 64,5 m2",
    tour:
      "https://tours.3destate.pl/Process/38d2b98c-0bfb-4bf9-bf51-6d0e9c8f62d0.3d",
  },
  {
    "0": "OJP2/B/P0/06",
    building: "OJP2",
    "2": "06",
    "3": "P0",
    "4": 40.08,
    "1": "B",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P0_06.pdf",
    "14": "residential",
    extra: "ogródek 35,9 m2",
    tour:
      "https://tours.3destate.pl/Process/c3c14459-ed99-4c19-a838-5e36296a7db6.3d",
  },
  {
    "0": "OJP2/B/P0/07",
    building: "OJP2",
    "2": "07",
    "3": "P0",
    "4": 35.64,
    "1": "B",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P0_07.pdf",
    "14": "residential",
    extra: "ogródek 8,3 m2 ",
    tour: "",
  },
  {
    "0": "OJP2/C/P0/01",
    building: "OJP2",
    "2": "01",
    "3": "P0",
    "4": 43.51,
    "1": "C",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P0_01.pdf",
    "14": "residential",
    extra: "ogródek 9m2",
    tour:
      "https://tours.3destate.pl/Process/5ded576b-0090-4a00-a915-eb7eb10579ea.3d",
  },
  {
    "0": "OJP2/C/P0/02",
    building: "OJP2",
    "2": "02",
    "3": "P0",
    "4": 59.58,
    "1": "C",
    "5": "3",
    "6": "wschód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P0_02.pdf",
    "14": "residential",
    extra: "ogródek 31,7m2",
    tour:
      "https://tours.3destate.pl/Process/ea752239-bc12-420e-a0d5-afa1e7230ac1.3d",
  },
  {
    "0": "OJP2/C/P0/03",
    building: "OJP2",
    "2": "03",
    "3": "P0",
    "4": 66.67,
    "1": "C",
    "5": "3",
    "6": "zachód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P0_03.pdf",
    "14": "residential",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/C/P0/04",
    building: "OJP2",
    "2": "04",
    "3": "P0",
    "4": 41.9,
    "1": "C",
    "5": "3",
    "6": "zachód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P0_04.pdf",
    "14": "residential",
    extra: "ogródek 62,9m2",
    tour:
      "https://tours.3destate.pl/Process/598dcdbc-0996-4736-bf12-92dafbdca4f4.3d",
  },
  {
    "0": "OJP2/C/P0/05",
    building: "OJP2",
    "2": "05",
    "3": "P0",
    "4": 40.34,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P0_05.pdf",
    "14": "residential",
    extra: "ogródek 57,8m2",
    tour:
      "https://tours.3destate.pl/Process/e4de17e2-08e5-44a5-89da-fd6c3a3a5a28.3d",
  },
  {
    "0": "OJP2/C/P0/06",
    building: "OJP2",
    "2": "06",
    "3": "P0",
    "4": 44.58,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P0_06.pdf",
    "14": "residential",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/C/P0/07",
    building: "OJP2",
    "2": "07",
    "3": "P0",
    "4": 41.89,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P0_07.pdf",
    "14": "residential",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/C/P0/08",
    building: "OJP2",
    "2": "08",
    "3": "P0",
    "4": 44.48,
    "1": "C",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P0_08.pdf",
    "14": "residential",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/C/P0/09",
    building: "OJP2",
    "2": "09",
    "3": "P0",
    "4": 47.29,
    "1": "C",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P0_09.pdf",
    "14": "residential",
    extra: "ogródek 19,9m2",
    tour:
      "https://tours.3destate.pl/Process/72e5420c-26b0-4165-b1bc-a31edcb5ec9c.3d",
  },
  {
    "0": "OJP2/C/P0/10",
    building: "OJP2",
    "2": "10",
    "3": "P0",
    "4": 57.36,
    "1": "C",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P0_10.pdf",
    "14": "residential",
    extra: "ogródek 23,3m2",
    tour:
      "https://tours.3destate.pl/Process/a045e1d9-28f1-4573-a803-fe0c0531e9a2.3d",
  },
  // {
  //   "0": "OJP2/A/P1/04",
  //   building: "OJP2",
  //   "2": "04",
  //   "3": "P1",
  //   "4": 29.97,
  //   "1": "A",
  //   "5": "1",
  //   "6": "północ,zachód,południe",
  //   "7": "Gotowe",
  //   "12": "medium",
  //   "13": "",
  //   "11": "/assets/files/janowo-2/pdf/OJPII_A_P1_04_umowa_rezerw.pdf",
  //   "14": "residential",
  //   extra: "",
  //   tour: "",
  // },
  {
    "0": "OJP2/A/P1/05",
    building: "OJP2",
    "2": "05",
    "3": "P1",
    "4": 20.41,
    "1": "A",
    "5": "1",
    "6": "zachód, południe, północ ",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_A_P1_05_umowa_rezerw.pdf",
    "14": "apartment",
    extra: "",
    tour: "",
  },

  {
    "0": "OJP2/B/P1/08",
    building: "OJP2",
    "2": "08",
    "3": "P1",
    "4": 43.51,
    "1": "B",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P1_08.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/51253e18-e542-4061-8597-7cb080967fca.3d",
  },
  {
    "0": "OJP2/B/P1/09",
    building: "OJP2",
    "2": "09",
    "3": "P1",
    "4": 44.25,
    "1": "B",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P1_09.pdf",
    "14": "residential",
    extra: "balkon 4,3m2",
    tour:
      "https://tours.3destate.pl/Process/837662b7-9c0e-407b-99ee-55d6e470aad2.3d",
  },
  {
    "0": "OJP2/B/P1/10",
    building: "OJP2",
    "2": "10",
    "3": "P1",
    "4": 46.05,
    "1": "B",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P1_10.pdf",
    "14": "residential",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/B/P1/11",
    building: "OJP2",
    "2": "11",
    "3": "P1",
    "4": 57.09,
    "1": "B",
    "5": "3",
    "6": "wschód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P1_11.pdf",
    "14": "residential",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/B/P1/12",
    building: "OJP2",
    "2": "12",
    "3": "P1",
    "4": 42.0,
    "1": "B",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P1_12.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/1a675b98-3e7f-4115-bc05-c42ebe7b1b2f.3d",
  },
  {
    "0": "OJP2/B/P1/13",
    building: "OJP2",
    "2": "13",
    "3": "P1",
    "4": 40.09,
    "1": "B",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P1_13.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/7352bf9d-44b1-463e-a75f-9a4c0739eb05.3d",
  },
  {
    "0": "OJP2/B/P1/14",
    building: "OJP2",
    "2": "14",
    "3": "P1",
    "4": 71.19,
    "1": "B",
    "5": "3",
    "6": "północ, południe",
    "7": "Gotowe",
    "12": "large",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P1_14.pdf",
    "14": "residential",
    extra: "balkon 4,1m2",
    tour:
      "https://tours.3destate.pl/Process/f04e4aaa-c4db-4490-8564-a03b06a53ff2.3d",
  },
  {
    "0": "OJP2/B/P1/15",
    building: "OJP2",
    "2": "15",
    "3": "P1",
    "4": 60.97,
    "1": "B",
    "5": "3",
    "6": "północ, wschód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P1_15.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/5a815995-c5fc-4dfd-a88f-2bd97be2c54c.3d",
  },
  {
    "0": "OJP2/B/P1/16",
    building: "OJP2",
    "2": "16",
    "3": "P1",
    "4": 40.07,
    "1": "B",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P1_16.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/7d044000-138a-4f83-a2a4-8716e44e8d33.3d",
  },
  {
    "0": "OJP2/B/P1/17",
    building: "OJP2",
    "2": "17",
    "3": "P1",
    "4": 29.58,
    "1": "B",
    "5": "1",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P1_17.pdf",
    "14": "residential",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/C/P1/11",
    building: "OJP2",
    "2": "11",
    "3": "P1",
    "4": 43.51,
    "1": "C",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P1_11.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/51253e18-e542-4061-8597-7cb080967fca.3d",
  },
  {
    "0": "OJP2/C/P1/12",
    building: "OJP2",
    "2": "12",
    "3": "P1",
    "4": 59.58,
    "1": "C",
    "5": "3",
    "6": "południe, wschód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P1_12.pdf",
    "14": "residential",
    extra: "balkon 7,6m2",
    tour:
      "https://tours.3destate.pl/Process/d9c59bda-2bde-4822-aeec-bdcefa37565c.3d",
  },
  {
    "0": "OJP2/C/P1/13",
    building: "OJP2",
    "2": "13",
    "3": "P1",
    "4": 66.67,
    "1": "C",
    "5": "3",
    "6": "zachód, południe",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P1_13.pdf",
    "14": "residential",
    extra: "balkon 7,6m2",
    tour:
      "https://tours.3destate.pl/Process/0c61ad03-083d-49b8-a903-cc0c30fce082.3d",
  },
  {
    "0": "OJP2/C/P1/14",
    building: "OJP2",
    "2": "14",
    "3": "P1",
    "4": 41.9,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P1_14.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/5b0feb60-01ee-4d96-867c-00986daca246.3d",
  },
  {
    "0": "OJP2/C/P1/15",
    building: "OJP2",
    "2": "15",
    "3": "P1",
    "4": 40.34,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P1_15.pdf",
    "14": "residential",
    extra: "balkon 3,2m2 ",
    tour:
      "https://tours.3destate.pl/Process/197719b2-a1d6-4fec-b621-5cee8417e2c6.3d",
  },
  {
    "0": "OJP2/C/P1/16",
    building: "OJP2",
    "2": "16",
    "3": "P1",
    "4": 44.58,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P1_16.pdf",
    "14": "residential",
    extra: "balkon 5,3m2",
    tour:
      "https://tours.3destate.pl/Process/71340d1e-40a9-4464-80f0-51c88728f63c.3d",
  },
  {
    "0": "OJP2/C/P1/17",
    building: "OJP2",
    "2": "17",
    "3": "P1",
    "4": 41.89,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P1_17.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/065f226a-2f3f-44d7-8551-2c84c2e735f5.3d",
  },
  {
    "0": "OJP2/C/P1/18",
    building: "OJP2",
    "2": "18",
    "3": "P1",
    "4": 44.48,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P1_18.pdf",
    "14": "residential",
    extra: "balkon 4,4m2",
    tour:
      "https://tours.3destate.pl/Process/f346b680-1e51-4426-b4d8-910d973e2986.3d",
  },
  {
    "0": "OJP2/C/P1/19",
    building: "OJP2",
    "2": "19",
    "3": "P1",
    "4": 47.29,
    "1": "C",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P1_19.pdf",
    "14": "residential",
    extra: "balkon 4,3m2",
    tour:
      "https://tours.3destate.pl/Process/d064a3bd-a46d-474e-ac7d-f323c6365ff4.3d",
  },
  {
    "0": "OJP2/C/P1/20",
    building: "OJP2",
    "2": "20",
    "3": "P1",
    "4": 35.6,
    "1": "C",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P1_20.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour: "",
  },
  {
    "0": "OJP2/C/P1/21",
    building: "OJP2",
    "2": "21",
    "3": "P1",
    "4": 29.59,
    "1": "C",
    "5": "1",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P1_21.pdf",
    "14": "residential",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/A/P2/06",
    building: "OJP2",
    "2": "06",
    "3": "P2",
    "4": 30.34,
    "1": "A",
    "5": "1",
    "6": "zachod, południe",
    "7": "Gotowe",
    "12": "medium",
    "13": "",
    "11": "/assets/files/janowo-2/pdf/OJPII_A_P2_06_umowa_rezerw.pdf",
    "14": "apartment",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/A/P2/07",
    building: "OJP2",
    "2": "07",
    "3": "P2",
    "4": 20.41,
    "1": "A",
    "5": "1",
    "6": "zachód, południe, północ",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_A_P2_07_umowa_rezerw.pdf",
    "14": "apartment",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/B/P2/18",
    building: "OJP2",
    "2": "18",
    "3": "P2",
    "4": 43.51,
    "1": "B",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P2_18.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/51253e18-e542-4061-8597-7cb080967fca.3d",
  },
  {
    "0": "OJP2/B/P2/19",
    building: "OJP2",
    "2": "19",
    "3": "P2",
    "4": 44.25,
    "1": "B",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P2_19.pdf",
    "14": "residential",
    extra: "balkon 4,3m2",
    tour:
      "https://tours.3destate.pl/Process/837662b7-9c0e-407b-99ee-55d6e470aad2.3d",
  },
  {
    "0": "OJP2/B/P2/20",
    building: "OJP2",
    "2": "20",
    "3": "P2",
    "4": 46.05,
    "1": "B",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P2_20.pdf",
    "14": "residential",
    extra: "balkon 4,3m2",
    tour:
      "https://tours.3destate.pl/Process/7e1bbb34-b18d-4788-82ad-a467c8802336.3d",
  },
  {
    "0": "OJP2/B/P2/21",
    building: "OJP2",
    "2": "21",
    "3": "P2",
    "4": 57.09,
    "1": "B",
    "5": "3",
    "6": "zachód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P2_21.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/928f2f52-84bb-4cbd-9b46-289b1a473661.3d",
  },
  {
    "0": "OJP2/B/P2/22",
    building: "OJP2",
    "2": "22",
    "3": "P2",
    "4": 42.0,
    "1": "B",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P2_22.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/1a675b98-3e7f-4115-bc05-c42ebe7b1b2f.3d",
  },
  {
    "0": "OJP2/B/P2/23",
    building: "OJP2",
    "2": "23",
    "3": "P2",
    "4": 40.08,
    "1": "B",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P2_23.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/7352bf9d-44b1-463e-a75f-9a4c0739eb05.3d",
  },
  {
    "0": "OJP2/B/P2/24",
    building: "OJP2",
    "2": "24",
    "3": "P2",
    "4": 71.19,
    "1": "B",
    "5": "3",
    "6": "północ,południe",
    "7": "Gotowe",
    "12": "large",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P2_24.pdf",
    "14": "residential",
    extra: "balkon 4,1m2",
    tour:
      "https://tours.3destate.pl/Process/f04e4aaa-c4db-4490-8564-a03b06a53ff2.3d",
  },
  {
    "0": "OJP2/B/P2/25",
    building: "OJP2",
    "2": "25",
    "3": "P2",
    "4": 60.97,
    "1": "B",
    "5": "3",
    "6": "północ, wschód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P2_25.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/5a815995-c5fc-4dfd-a88f-2bd97be2c54c.3d",
  },
  {
    "0": "OJP2/B/P2/26",
    building: "OJP2",
    "2": "26",
    "3": "P2",
    "4": 40.0,
    "1": "B",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P2_26.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/7d044000-138a-4f83-a2a4-8716e44e8d33.3d",
  },
  {
    "0": "OJP2/B/P2/27",
    building: "OJP2",
    "2": "27",
    "3": "P2",
    "4": 29.58,
    "1": "B",
    "5": "1",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P2_27.pdf",
    "14": "residential",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/C/P2/22",
    building: "OJP2",
    "2": "22",
    "3": "P2",
    "4": 43.51,
    "1": "C",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P2_22.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/51253e18-e542-4061-8597-7cb080967fca.3d",
  },
  {
    "0": "OJP2/C/P2/23",
    building: "OJP2",
    "2": "23",
    "3": "P2",
    "4": 59.58,
    "1": "C",
    "5": "3",
    "6": "wschód,południe",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P2_23.pdf",
    "14": "residential",
    extra: "balkon 7,6m2",
    tour:
      "https://tours.3destate.pl/Process/d9c59bda-2bde-4822-aeec-bdcefa37565c.3d",
  },
  {
    "0": "OJP2/C/P2/24",
    building: "OJP2",
    "2": "24",
    "3": "P2",
    "4": 66.67,
    "1": "C",
    "5": "3",
    "6": "zachód, południe",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P2_24.pdf",
    "14": "residential",
    extra: "balkon 7,6m2",
    tour:
      "https://tours.3destate.pl/Process/0c61ad03-083d-49b8-a903-cc0c30fce082.3d",
  },
  {
    "0": "OJP2/C/P2/25",
    building: "OJP2",
    "2": "25",
    "3": "P2",
    "4": 41.9,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P2_25.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/5b0feb60-01ee-4d96-867c-00986daca246.3d",
  },
  {
    "0": "OJP2/C/P2/26",
    building: "OJP2",
    "2": "26",
    "3": "P2",
    "4": 40.34,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P2_26.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour: "",
  },
  {
    "0": "OJP2/C/P2/27",
    building: "OJP2",
    "2": "27",
    "3": "P2",
    "4": 44.58,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P2_27.pdf",
    "14": "residential",
    extra: "balkon 5,3m2",
    tour:
      "https://tours.3destate.pl/Process/71340d1e-40a9-4464-80f0-51c88728f63c.3d",
  },
  {
    "0": "OJP2/C/P2/28",
    building: "OJP2",
    "2": "28",
    "3": "P2",
    "4": 41.89,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P2_28.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/065f226a-2f3f-44d7-8551-2c84c2e735f5.3d",
  },
  {
    "0": "OJP2/C/P2/29",
    building: "OJP2",
    "2": "29",
    "3": "P2",
    "4": 44.48,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P2_29.pdf",
    "14": "residential",
    extra: "balkon 4,4m2",
    tour:
      "https://tours.3destate.pl/Process/f346b680-1e51-4426-b4d8-910d973e2986.3d",
  },
  {
    "0": "OJP2/C/P2/30",
    building: "OJP2",
    "2": "30",
    "3": "P2",
    "4": 47.29,
    "1": "C",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P2_30.pdf",
    "14": "residential",
    extra: "balkon 4,3m2",
    tour:
      "https://tours.3destate.pl/Process/d064a3bd-a46d-474e-ac7d-f323c6365ff4.3d",
  },
  {
    "0": "OJP2/C/P2/31",
    building: "OJP2",
    "2": "31",
    "3": "P2",
    "4": 35.53,
    "1": "C",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P2_31.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour: "",
  },
  {
    "0": "OJP2/C/P2/32",
    building: "OJP2",
    "2": "32",
    "3": "P2",
    "4": 29.58,
    "1": "C",
    "5": "1",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P2_32.pdf",
    "14": "residential",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/A/P3/08",
    building: "OJP2",
    "2": "08",
    "3": "P3",
    "4": 30.34,
    "1": "A",
    "5": "1",
    "6": "zachód, południe",
    "7": "Gotowe",
    "12": "small",
    "13": "",
    "11": "/assets/files/janowo-2/pdf/OJPII_A_P3_08_umowa_rezerw.pdf",
    "14": "apartment",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/A/P3/09",
    building: "OJP2",
    "2": "09",
    "3": "P3",
    "4": 20.41,
    "1": "A",
    "5": "1",
    "6": "południe",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_A_P3_09_umowa_rezerw.pdf",
    "14": "apartment",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/B/P3/28",
    building: "OJP2",
    "2": "28",
    "3": "P3",
    "4": 43.51,
    "1": "B",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P3_28.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/51253e18-e542-4061-8597-7cb080967fca.3d",
  },
  {
    "0": "OJP2/B/P3/29",
    building: "OJP2",
    "2": "29",
    "3": "P3",
    "4": 44.25,
    "1": "B",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P3_29.pdf",
    "14": "residential",
    extra: "balkon 4,3m2",
    tour:
      "https://tours.3destate.pl/Process/837662b7-9c0e-407b-99ee-55d6e470aad2.3d",
  },
  {
    "0": "OJP2/B/P3/30",
    building: "OJP2",
    "2": "30",
    "3": "P3",
    "4": 46.05,
    "1": "B",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P3_30.pdf",
    "14": "residential",
    extra: "balkon 4,3m2",
    tour:
      "https://tours.3destate.pl/Process/7e1bbb34-b18d-4788-82ad-a467c8802336.3d",
  },
  {
    "0": "OJP2/B/P3/31",
    building: "OJP2",
    "2": "31",
    "3": "P3",
    "4": 57.09,
    "1": "B",
    "5": "3",
    "6": "zachód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P3_31.pdf",
    "14": "residential",
    extra: "balkon 3,2m2 ",
    tour:
      "https://tours.3destate.pl/Process/928f2f52-84bb-4cbd-9b46-289b1a473661.3d",
  },
  {
    "0": "OJP2/B/P3/32",
    building: "OJP2",
    "2": "32",
    "3": "P3",
    "4": 42.0,
    "1": "B",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P3_32.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/1a675b98-3e7f-4115-bc05-c42ebe7b1b2f.3d",
  },
  {
    "0": "OJP2/B/P3/33",
    building: "OJP2",
    "2": "33",
    "3": "P3",
    "4": 40.08,
    "1": "B",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P3_33.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/7352bf9d-44b1-463e-a75f-9a4c0739eb05.3d",
  },
  // {
  //   "0": "OJP2/B/P3/34",
  //   building: "OJP2",
  //   "2": "34",
  //   "3": "P3",
  //   "4": 71.19,
  //   "1": "B",
  //   "5": "3",
  //   "6": "północ, południe",
  //   "7": "Gotowe",
  //   "12": "large",
  //   "13": "",
  //   "11": "/assets/files/janowo-2/pdf/OJPII_B_P3_34.pdf",
  //   "14": "residential",
  //   extra: "balkon 4,1m2",
  //   tour: "",
  // },
  {
    "0": "OJP2/B/P3/35",
    building: "OJP2",
    "2": "35",
    "3": "P3",
    "4": 60.97,
    "1": "B",
    "5": "3",
    "6": "północ, wschód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P3_35.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/5a815995-c5fc-4dfd-a88f-2bd97be2c54c.3d",
  },
  {
    "0": "OJP2/B/P3/36",
    building: "OJP2",
    "2": "36",
    "3": "P3",
    "4": 40.0,
    "1": "B",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P3_36.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/7d044000-138a-4f83-a2a4-8716e44e8d33.3d",
  },
  {
    "0": "OJP2/B/P3/37",
    building: "OJP2",
    "2": "37",
    "3": "P3",
    "4": 29.58,
    "1": "B",
    "5": "1",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_B_P3_37.pdf",
    "14": "residential",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/C/P3/33",
    building: "OJP2",
    "2": "33",
    "3": "P3",
    "4": 43.51,
    "1": "C",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P3_33.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/51253e18-e542-4061-8597-7cb080967fca.3d",
  },
  {
    "0": "OJP2/C/P3/34",
    building: "OJP2",
    "2": "34",
    "3": "P3",
    "4": 59.58,
    "1": "C",
    "5": "3",
    "6": "wschód, południe",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P3_34.pdf",
    "14": "residential",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/C/P3/35",
    building: "OJP2",
    "2": "35",
    "3": "P3",
    "4": 66.67,
    "1": "C",
    "5": "3",
    "6": "zachód, południe",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P3_35.pdf",
    "14": "residential",
    extra: "balkon 7,6m2",
    tour:
      "https://tours.3destate.pl/Process/0c61ad03-083d-49b8-a903-cc0c30fce082.3d",
  },
  {
    "0": "OJP2/C/P3/36",
    building: "OJP2",
    "2": "36",
    "3": "P3",
    "4": 41.9,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P3_36.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/5b0feb60-01ee-4d96-867c-00986daca246.3d",
  },
  {
    "0": "OJP2/C/P3/37",
    building: "OJP2",
    "2": "37",
    "3": "P3",
    "4": 40.34,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P3_37.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/197719b2-a1d6-4fec-b621-5cee8417e2c6.3d",
  },
  {
    "0": "OJP2/C/P3/38",
    building: "OJP2",
    "2": "38",
    "3": "P3",
    "4": 44.58,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P3_38.pdf",
    "14": "residential",
    extra: "balkon 5,3m2",
    tour:
      "https://tours.3destate.pl/Process/71340d1e-40a9-4464-80f0-51c88728f63c.3d",
  },
  {
    "0": "OJP2/C/P3/39",
    building: "OJP2",
    "2": "39",
    "3": "P3",
    "4": 41.89,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P3_39.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/065f226a-2f3f-44d7-8551-2c84c2e735f5.3d",
  },
  {
    "0": "OJP2/C/P3/40",
    building: "OJP2",
    "2": "40",
    "3": "P3",
    "4": 44.48,
    "1": "C",
    "5": "2",
    "6": "zachód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P3_40.pdf",
    "14": "residential",
    extra: "",
    tour: "",
  },
  {
    "0": "OJP2/C/P3/41",
    building: "OJP2",
    "2": "41",
    "3": "P3",
    "4": 47.29,
    "1": "C",
    "5": "2",
    "6": "wschód",
    "7": "Gotowe",
    "12": "small",
    "13": "true",
    "11": "/assets/files/janowo-2/pdf/OJPII_C_P3_41.pdf",
    "14": "residential",
    extra: "balkon 4,3m2",
    tour:
      "https://tours.3destate.pl/Process/d064a3bd-a46d-474e-ac7d-f323c6365ff4.3d",
  },
  {
    "0": "OJP2/C/P3/42",
    building: "OJP2",
    "2": "42",
    "3": "P3",
    "4": 65.11,
    "1": "C",
    "5": "3",
    "6": "wschód",
    "7": "Gotowe",
    "12": "medium",
    "13": "true",
    // '11': "/assets/files/janowo-2/pdf/OJPII_C_P3_42.pdf",
    "14": "residential",
    extra: "balkon 3,2m2",
    tour:
      "https://tours.3destate.pl/Process/c329b82c-187f-4477-af9d-ed5cc97f9c5a.3d",
  },
]
